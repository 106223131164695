import React, { useState, useCallback, useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import CarouselNavigation from './carouselNavigation';

export const DragCarousel = ({ children, showNav }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    skipSnaps: false,
    align: 'start',
    containScroll: true,
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
  }, [embla, onSelect]);

  return (
    <div className='relative group'>
      <div ref={viewportRef} className='relative'>
        {children}
      </div>
      {showNav && (
        <CarouselNavigation
          prevBtnEnabled={prevBtnEnabled}
          nextBtnEnabled={nextBtnEnabled}
          scrollPrev={scrollPrev}
          scrollNext={scrollNext}
          centerArrows
        />
      )}
    </div>
  );
};
