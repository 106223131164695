import React from 'react';
import cx from 'classnames';
import ArrowL from '@svg/arrow-l.svg';
import ArrowR from '@svg/arrow-r.svg';

const CarouselNavigation = ({
  prevBtnEnabled,
  nextBtnEnabled,
  scrollPrev,
  scrollNext,
  centerArrows,
  hideOnMobile,
  extraPadding,
}) => {
  return (
    <nav
      className={cx(
        'justify-between items-center absolute left-0 h-full w-full transition-opacity duration-700 pointer-events-none',
        {
          'hidden md:flex': hideOnMobile,
          flex: !hideOnMobile,
          'opacity-100 lg:opacity-0 hover:opacity-100': !hideOnMobile,
          'top-0 transform -translate-y-16': !centerArrows,
          'top-1/2 transform -translate-y-1/2': centerArrows,
          'px-nav-gutter': extraPadding,
        }
      )}
    >
      <button
        className={cx(
          'bg-white w-14 h-14 text-black flex items-center justify-center rounded-full shadow-xl pointer-events-auto',
          {
            'opacity-0': !prevBtnEnabled,
          }
        )}
        onClick={scrollPrev}
      >
        <ArrowL className='fill-current w-4' />
      </button>
      <button
        className={cx(
          'bg-white w-14 h-14 text-black flex items-center justify-center rounded-full shadow-xl pointer-events-auto',
          {
            'opacity-0': !nextBtnEnabled,
          }
        )}
        onClick={scrollNext}
      >
        <ArrowR className='fill-current w-4' />
      </button>
    </nav>
  );
};

export default CarouselNavigation;
