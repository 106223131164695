import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';

import { useSanityPath } from '@hooks/useSanityPath';
import { SanityImage } from '@components/sanity/sanityImage';

export const FeatureCard = ({
  image,
  title,
  subtitle,
  linkText,
  page,
  aspectRatio = 'rectangle',
  className,
  textColor,
  isPromo = false,
  isSlider = false,
}) => {
  const path = useSanityPath(page);
  return (
    <div
      className={cx(
        `${className} grid relative bg-black rounded-xl overflow-hidden`,
        {
          'text-white': textColor === 'white',
          'text-black': textColor === 'black',
          'group md:scale-95 transform md:hover:scale-100 transition-all duration-500':
            !isPromo && !isSlider,
        }
      )}
    >
      <div
        className={cx('relative col-start-1 row-start-1', {
          'aspect-w-1 aspect-h-1': aspectRatio === 'square',
          'aspect-w-9 aspect-h-4': aspectRatio === 'rectangle',
          'aspect-w-6 aspect-h-8': aspectRatio === 'portrait',
          'aspect-w-6 aspect-h-6 md:aspect-h-4 lg:aspect-w-6 lg:aspect-h-5 2xl:aspect-h-4':
            isPromo,
        })}
      >
        {aspectRatio === 'portrait' && textColor === 'white' && (
          <span className='bg-black w-full h-full bg-opacity-20 absolute inset-0 left-0 z-10' />
        )}
        <SanityImage
          image={image}
          className={cx('absolute inset-0 object-cover', {
            'opacity-60': isPromo,
            'opacity-80': !isPromo,
          })}
          width={1000}
        />
      </div>
      <Link
        to={path}
        className={cx('col-start-1 row-start-1 relative z-10 p-4 lg:p-10', {
          'self-center': aspectRatio === 'rectangle',
          'self-end': aspectRatio === 'portrait' || isPromo,
        })}
      >
        <div
          className={cx({
            'group-hover:-translate-y-4 transform transition-all duration-700':
              !isPromo,
          })}
        >
          <h5 className='subOne'>{subtitle}</h5>
          <h2
            className={cx('mb-half pr-10 max-w-xs', {
              blockH1: isPromo,
              'text-xl md:text-3xl': !isPromo,
            })}
          >
            {title}
          </h2>
        </div>
        <div
          className={cx({
            'group-hover:-translate-y-4 transform transition-all duration-700 delay-75':
              !isPromo,
          })}
        >
          <span
            className={cx('btn', {
              white: textColor === 'white',
            })}
          >
            {linkText}
          </span>
        </div>
      </Link>
    </div>
  );
};
